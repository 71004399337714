import React from 'react';

const BrandList = [
    {
        image: './images/brand/cardinal.png'
    },
    {
        image: './images/brand/foxconn.png'
    },
    {
        image: './images/brand/jc.png'
    },
    {
        image: './images/brand/bd.png'
    },
    {
        image: './images/brand/commscope.png'
    },
    {
        image: './images/brand/nefab.png'
    },
    {
        image: './images/brand/syncreon.png'
    },
    {
        image: './images/brand/fanosa.png'
    },
    {
        image: './images/brand/kufferath.png'
    },
    {
        image: './images/brand/tenneco.png'
    },
    {
        image: './images/brand/ilpea.png'
    },
    {
        image: './images/brand/uacj.png'
    },
    {
        image: './images/brand/seisa.png'
    },
    {
        image: './images/brand/te.png'
    },
    {
        image: './images/brand/mogul.png'
    },
    {
        image: './images/brand/flutec.png'
    },
  
  
]

const BrandThree = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandThree;
