import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';





// Respuesta al enviar el eamil
const Result = () => {
    return (
        <p className="success-message">
            Your Message has been successfully sent. I will contact you soon.
        </p>
    )
}



function ContactForm({ props, formStyle }) {

    const [result, showresult] = useState(false);

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_lmv9ckw',
                'template_gjl57na',
                form.current,
                'y5yiHTl69LhPgcarZ'
            )
            .then((result) => {
                console.log(result.text);
                showresult(true);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);



    return (
        <form className={`${formStyle}`} ref={form} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input
                    type="text"
                    name="from_name"
                    placeholder="Tu Nombre"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="email"
                    name="from_email"
                    placeholder="Email"
                    required
                />
            </div>

            <div className="form-group">
                <input
                    type="text"
                    name="celular"
                    placeholder="Teléfono:"
                    required
                />
            </div>
           

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder="Tu Mensaje"
                    required
                >
                </textarea>
            </div>

            <div className="form-group">
                <button className="btn-default btn-large">Enviar</button>
            </div>

            <div className="form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
