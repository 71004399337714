import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import AboutTwo from "../elements/about/AboutTwo";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";
import PricingFive from "../elements/pricing/PricingFive";
import BrandOne from "../elements/brand/BrandOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import ServiceFive from '../elements/service/ServiceFive';
import GalleryOne from '../elements/gallery/GalleryOne';


const ServiceMaquilado = () => {

    const cncData = [
        {
            id: "01",
            image: "./images/portfolio/portfolio-01.svg",
            popupLink: [
                './images/portfolio/portfolio-01.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/portfolio-02.svg",
            popupLink: [
                './images/portfolio/portfolio-02.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/portfolio-03.svg",
            popupLink: [
                './images/portfolio/portfolio-03.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/portfolio-04.svg",
            popupLink: [
                './images/portfolio/portfolio-04.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/portfolio-05.svg",
            popupLink: [
                './images/portfolio/portfolio-05.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/portfolio-06.svg",
            popupLink: [
                './images/portfolio/portfolio-06.svg',

            ],
        },
    ]

    const cncMaderaData = [
        {
            id: "01",
            image: "./images/portfolio/cncMadera-01.svg",
            popupLink: [
                './images/portfolio/cncMadera-01.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/cncMadera-02.svg",
            popupLink: [
                './images/portfolio/cncMadera-02.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/cncMadera-03.svg",
            popupLink: [
                './images/portfolio/cncMadera-03.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/cncMadera-04.svg",
            popupLink: [
                './images/portfolio/cncMadera-04.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/cncMadera-05.svg",
            popupLink: [
                './images/portfolio/cncMadera-05.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/cncMadera-06.svg",
            popupLink: [
                './images/portfolio/cncMadera-06.svg',

            ],
        },
    ]

    const troqueladoData = [
        {
            id: "01",
            image: "./images/portfolio/troquelado-01.svg",
            popupLink: [
                './images/portfolio/troquelado-01.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/troquelado-02.svg",
            popupLink: [
                './images/portfolio/troquelado-02.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/troquelado-03.svg",
            popupLink: [
                './images/portfolio/troquelado-03.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/troquelado-04.svg",
            popupLink: [
                './images/portfolio/troquelado-04.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/troquelado-05.svg",
            popupLink: [
                './images/portfolio/troquelado-05.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/troquelado-06.svg",
            popupLink: [
                './images/portfolio/troquelado-06.svg',

            ],
        },
    ]
    const retrabajoData = [
        {
            id: "01",
            image: "./images/portfolio/retrabajo-01.svg",
            popupLink: [
                './images/portfolio/retrabajo-01.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/retrabajo-02.svg",
            popupLink: [
                './images/portfolio/retrabajo-02.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/retrabajo-03.svg",
            popupLink: [
                './images/portfolio/retrabajo-03.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/retrabajo-04.svg",
            popupLink: [
                './images/portfolio/retrabajo-04.svg',

            ],
        },
        {
            id: "01",
            image: "./images/portfolio/retrabajo-05.svg",
            popupLink: [
                './images/portfolio/retrabajo-05.svg',

            ],
        },
       
    ]

    


    return (
        <>
            <SEO title="Maquinado Industrial" />
            <main className="page-wrapper">

                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">Maquilado <br />Industrial</h1>
                                    <p className="description color-white">
                                        ¡Socios amigables para tu maquilado, comprometidos con proyectos de ahorro y mejora continua!
                                    </p>
                                    {/* <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Purchase Now <i className="icon"><FiArrowRight /></i></a>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/portfolio/retrabajo-05.svg" alt="Banner Images" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-gallery-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--20">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    // subtitle="Producción CNC"
                                    title="Producción CNC."
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--30 row--15">
                            {cncData.map((item) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-gallery-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--20">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    // subtitle="Producción CNC"
                                    title="Producción CNC de madera."
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--30 row--15">
                            {cncMaderaData.map((item) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-gallery-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--20">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    // subtitle="Producción CNC"
                                    title="Troquelado."
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--30 row--15">
                            {troqueladoData.map((item) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Elements Area  */}
                <div className="rwt-gallery-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--20">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    // subtitle="Producción CNC"
                                    title="Retrabajo."
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row mt_dec--30 row--15">
                            {retrabajoData.map((item) => (
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                    <GalleryOne galleryItem={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Elements Area  */}



                <Separator />

                <CalltoActionSix />


                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    // subtitle="What we can do for you"
                                    // title="Services provide for you."
                                    description="Diseño Industrial"
                                />
                            </div>
                        </div>
                        <ServiceFive
                            serviceStyle="gallery-style"
                            textAlign="text-start"
                        />
                    </div>
                </div> */}
                {/* End Service Area  */}



                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default ServiceMaquilado
