import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const AboutTwo = () => {
    return (
        <div className="rwt-about-area about-style-2 rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/pfi_about.svg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    {/* <h4 className="subtitle"><span className="theme-gradient">Corporate About.</span></h4> */}
                                    <h2 className="title mt--10">ACERCA DE NOSOTROS.</h2>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>

                                    <span style={{ textTransform: 'uppercase' }}>
                                        <p>
                                            PFI es una empresa socialmente
                                            Responsable con la industria maquiladora, prestando servicios desde
                                            el año 2005.
                                            <br />
                                            Dentro de los cuales contamos con una amplia gama de productos y servicios,
                                            en los que se encuentran dentro de:
                                        </p>
                                    </span>

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> MRO</li>
                                        <li><span className="icon"><FiCheck /></span> MAQUILADO</li>
                                        <li><span className="icon"><FiCheck /></span> MAQUINADOS DE PRECISION</li>
                                        <li><span className="icon"><FiCheck /></span> DISEÑO INDUSTRIAL y MANUFACTURA </li>
                                        <li><span className="icon"><FiCheck /></span> AUTOMATIZACIÓN e INTEGRACIÓN</li>
                                        <li><span className="icon"><FiCheck /></span> BOBINADO DE MOTORES</li>
                                        <li><span className="icon"><FiCheck /></span> SILLERÍA ERGONÓMICA Y SECRETARIAL</li>
                                    </ul>

                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    {/* <div className="read-more-btn mt--40">
                                            <Link className="btn-default btn-icon" to="#">More About Us <i className="icon"><FiArrowRight /></i></Link>
                                        </div> */}
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;
