import React from 'react';
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import HeaderTopBar from '../common/header/HeaderTopBar';
import HeaderOne from '../common/header/HeaderOne';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import ServiceTwo from '../elements/service/ServiceTwo';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import AboutTwo from "../elements/about/AboutTwo";
import CounterUpTwo from "../elements/counterup/CounterUpTwo";

import Separator from "../elements/separator/Separator";
import TeamFour from '../elements/team/TeamFour';
import PricingTwo from '../elements/pricing/PricingTwo';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import ServicePlomeria from '../elements/service/ServicePlomeria';
import ServiceFerretero from '../elements/service/ServiceFerretero';
import ServiceHerramientas from '../elements/service/ServiceHerramientas';
import ServiceLimpieza from '../elements/service/ServiceLimpieza';
import ServiceConstruccion from '../elements/service/ServiceConstruccion';
import ServiceSeguridad from '../elements/service/ServiceSeguridad';
import BrandElectrica from '../elements/brand/BrandElectrica';
import BrandPlomeria from '../elements/brand/BrandPlomeria';
import BrandFerretero from '../elements/brand/BrandFerretero';
import BrandHerramientas from '../elements/brand/BrandHerramientas';
import BrandLimpieza from '../elements/brand/BrandLimpieza';
import BrandConstruccion from '../elements/brand/BrandConstruccion';
import BrandSeguridad from '../elements/brand/BrandSeguridad';
import ServiceThree from '../elements/service/ServiceThree';
import BrandMotores from '../elements/brand/BrandMotores';
import ServiceFour from '../elements/service/ServiceFour';
import BrandThree from '../elements/brand/BrandThree';
import ServiceFive from '../elements/service/ServiceFive';


const Corporate = () => {
    return (
        <>
            <SEO title="PFI CDJ" />
            <main className="page-wrapper">

                <div className="header-transparent-with-topbar">
                    <HeaderTopBar />
                    <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
                </div>

                {/* Start Slider area  */}
                <div className="slider-area slider-style-2 height-950 bg_image" data-black-overlay="2" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/banner_home.svg)` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-start">
                                    <h1 className="title display-one">  Proveedora Fronteriza Industrial de Juarez S.A de C.V</h1>
                                    {/* <h2 className="title display-three">Maquilados, maquinados,mero industrial</h2> */}
                                    <h4 className="subtitle"><span className="theme-gradient">Contamos con una amplia gama en:</span></h4>
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Productos.</li>
                                        <li><span className="icon"><FiCheck /></span> Servicios.</li>

                                    </ul>
                                    <div className="button-group mt--40 mt_sm--20">
                                    <Link to="/contact"><button className="btn-default btn-icon">  Contáctenos ahora<i className="icon"><FiArrowRight /></i></button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider area  */}

                <AboutTwo />





                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Servicios disponibles para tu negocio"
                                    title="¿En que podemos ayudarte?"
                                // description="Tenemos diferentes servicios y productos que harán tu trabajo mas fácil y rentable."
                                />
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <SectionTitle

                                    textAlign="text-center"
                                    radiusRounded=""
                                    // subtitle=""
                                    // title="Embobinado de Motores"
                                    description="Somos el proveedor más confiable y con mayor surtido del mercado en Cd Juarez, nuestro servicio y producto cumplirán con tus necesidades en tu negocio. "
                                />
                                <ServiceThree
                                    serviceStyle="service__style--2"
                                    textAlign="text-center"
                                />
                                <div className="container">
                                    {/* <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                          g  textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                        </div>
                                    </div> */}
                                    {/* <div className="row">
                                        <div className="col-lg-12 mt--10">
                                            <BrandMotores brandStyle="brand-style-2" />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>





                    </div>
                </div>
                {/* End Service Area  */}





                <Separator />

                {/* Start Brand Area  */}
                <div className="rwt-brand-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Nuestros Clientes"
                                    title="Ellos ya confían en nosotros."
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}







                {/* Start Call To Action Area  */}
                {/* <div className="rwt-callto-action-area rn-section-gapBottom">
                    <div className="wrapper">
                        <CalltoActionFive />
                    </div>
                </div> */}
                {/* End Call To Action Area  */}

                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default Corporate
