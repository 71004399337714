import React from 'react';

const BrandList = [
    {
        image: './images/brand/hubble.png'
    },
    {
        image: './images/brand/thomas.png'
    },
    {
        image: './images/brand/square.png'
    },
    {
        image: './images/brand/cooper.png'
    },
    {
        image: './images/brand/3m.png'
    },
    {
        image: './images/brand/siemens.png'
    },
    {
        image: './images/brand/viakon.png'
    },
    {
        image: './images/brand/rymco.png'
    },
    {
        image: './images/brand/abb.png'
    },
    {
        image: './images/brand/bticino.png'
    },
    {
        image: './images/brand/ge.png'
    },
    {
        image: './images/brand/leviton.png'
    },
    
    
    
]

const BrandElectrica = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandElectrica;
