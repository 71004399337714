import React from 'react';

const BrandList = [
    {
        image: './images/brand/honeywell.png'
    },
    {
        image: './images/brand/vicsa.png'
    },
    {
        image: './images/brand/jyrsa.png'
    },
    {
        image: './images/brand/uline.png'
    },
    {
        image: './images/brand/lift.png'
    },
    {
        image: './images/brand/miller.png'
    },
    {
        image: './images/brand/infra.png'
    },
    
    
    
]

const BrandSeguridad = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandSeguridad;
