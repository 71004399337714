import React from 'react';

const BrandList = [
    {
        image: './images/brand/b&d.png'
    },
    {
        image: './images/brand/makita.png'
    },
    {
        image: './images/brand/urrea.png'
    },
    {
        image: './images/brand/skil.png'
    },
    {
        image: './images/brand/dremel.png'
    },
    {
        image: './images/brand/stanley.png'
    },
    
    
]

const BrandHerramientas = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandHerramientas;
