import React from 'react';
import { Link } from "react-router-dom";

import Slider from 'react-slick'; // Importa Slider de react-slick

import ScrollAnimation from "react-animate-on-scroll";

// lista de servicios
const ServiceList = [
    {
        image: '/images/service/pulidora.png',
        title: 'Cortadoras y Pulidoras',

    },
    {
        image: '/images/service/taladro.png',
        title: 'Taladros y Rotomartillos',
        // description: 'Passages there are many variatin Lorem Ipsum available majority suffered.'
    },
    {
        image: '/images/service/esmeril.png',
        title: 'Zauzal y Esmeriladora',
        // description: 'Variations There are many of pass ages of Lorem Ipsum available the majority.'
    },
    {
        image: '/images/service/sierrra.png',
        title: 'Sierras y Tornillos de Banco',
        // description: 'Variations There are many of pass ages of Lorem Ipsum available the majority.'
    },
    {
        image: '/images/service/martillos.png',
        title: 'Martillos y Destornilladores'
    },
    {
        image: '/images/service/mecanica.png',
        title: 'Herramienta Mecanica'
    },
    {
        image: '/images/service/torque.png',
        title: 'Torques, Caladoras y Trolleys'
    },
    {
        image: '/images/service/torno.png',
        title: 'Herramienta Para Torno'
    },
    {
        image: '/images/service/cortadoras.png',
        title: 'Chuck, Boquillas y Cortadores'
    },
]

const ServiceHerramientas = ({ textAlign, cardStyle }) => {

    const settings = {
        dots: true, // Muestra los puntos de navegación
        infinite: true, // Habilita la navegación infinita
        speed: 1000, // Velocidad de transición
        slidesToShow: 3, // Cantidad de elementos visibles a la vez
        slidesToScroll: 3, // Cantidad de elementos para desplazarse en cada cambio
        autoplay: true, // Habilita la reproducción automática
        autoplaySpeed: 3000, // Velocidad de la reproducción automática en milisegundos (5 segundos en este caso)
        responsive: [
            {
                breakpoint: 768, // Punto de quiebre para dispositivos móviles
                settings: {
                    slidesToShow: 2, // Mostrar 2 slides en dispositivos móviles
                    slidesToScroll: 2,
                },
            },
        ],
    };

    return (
        <div className="row row--15 service-wrapper">
            <Slider {...settings} >

                {ServiceList.map((val, i) => (
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <div className={`card-box ${cardStyle} ${textAlign}`}>
                                <div className="inner">
                                    <div className="image">
                                        <Link to="/contact">
                                            <img src={`${val.image}`} alt="card Images" />
                                        </Link>
                                    </div>

                                    <div className="content">
                                        <h4 className="title mb--20"><Link to="/contact" dangerouslySetInnerHTML={{ __html: val.title }}></Link></h4>
                                        <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                        <Link className="btn-default btn-small btn-border" to="/contact">Cotizar</Link>
                                    </div>

                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                ))}

            </Slider>
        </div>
    )
}
export default ServiceHerramientas;