import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import AboutTwo from "../elements/about/AboutTwo";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";
import PricingFive from "../elements/pricing/PricingFive";
import BrandOne from "../elements/brand/BrandOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterOne from '../common/footer/FooterOne';
import ServiceTwo from '../elements/service/ServiceTwo';
import BrandElectrica from '../elements/brand/BrandElectrica';
import ServicePlomeria from '../elements/service/ServicePlomeria';
import BrandPlomeria from '../elements/brand/BrandPlomeria';
import ServiceFerretero from '../elements/service/ServiceFerretero';
import BrandFerretero from '../elements/brand/BrandFerretero';
import ServiceHerramientas from '../elements/service/ServiceHerramientas';
import BrandHerramientas from '../elements/brand/BrandHerramientas';
import ServiceLimpieza from '../elements/service/ServiceLimpieza';
import BrandLimpieza from '../elements/brand/BrandLimpieza';
import ServiceConstruccion from '../elements/service/ServiceConstruccion';
import BrandConstruccion from '../elements/brand/BrandConstruccion';
import ServiceSeguridad from '../elements/service/ServiceSeguridad';
import BrandSeguridad from '../elements/brand/BrandSeguridad';


const ServiceMRO = () => {
    return (
        <>
            <SEO title="MRO Industrial" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h1 className="title color-white">MRO <br />Industrial</h1>
                                    <p className="description color-white">Te ayudamos a encontrar toda la materia prima que requieras para que tu negocio no pare de producir.</p>
                                    {/* <div className="button-group mt--30 mt_sm--20">
                                        <a className="btn-default btn-medium btn-icon btn-border" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Purchase Now <i className="icon"><FiArrowRight /></i></a>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/banner/mro.svg" alt="Banner Images" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="./images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <CalltoActionSix />

                <Separator />






                {/* MRO instalaciones electricas */}
                <div className="container" style={{ marginTop: 40 }}>
                    <div className="row">

                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle=""
                            // title=""
                            description="MRO. Instalaciones Eléctricas"
                        />

                        <ServiceTwo
                            cardStyle="card-style-1"
                            textAlign="text-start"
                        />
                        <div className="container">
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                        </div>
                                    </div> */}
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandElectrica brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* MRO Instalaciones Plomaría */}
                <div className="container" style={{ marginTop: 40 }}>
                    <div className="row">

                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle=""
                            // title=""
                            description="MRO. Instalaciones Plomería"
                        />
                        <ServicePlomeria
                            cardStyle="card-style-1"
                            textAlign="text-start"
                        />
                        <div className="container">
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                    <SectionTitle
                                    textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                        </div>
                                    </div> */}
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandPlomeria brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MRO Material Ferretero */}
                <div className="container" style={{ marginTop: 40 }}>
                    <div className="row">

                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle=""
                            // title=""
                            description="MRO. Material Ferretero"
                        />
                        <ServiceFerretero
                            cardStyle="card-style-1"
                            textAlign="text-start"
                        />
                        <div className="container">
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                    </div>
                                </div> */}
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandFerretero brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* MRO Material Herramientas */}
                <div className="container" style={{ marginTop: 40 }}>
                    <div className="row">
                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle=""
                            // title=""
                            description="MRO. Herramientas"
                        />
                        <ServiceHerramientas
                            cardStyle="card-style-1"
                            textAlign="text-start"
                        />
                        <div className="container">
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                    </div>
                                </div> */}
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandHerramientas brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* MRO Limpieza */}
                <div className="container" style={{ marginTop: 40 }}>
                    <div className="row">

                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle=""
                            // title=""
                            description="MRO. Limpieza"
                        />
                        <ServiceLimpieza
                            cardStyle="card-style-1"
                            textAlign="text-start"
                        />
                        <div className="container">
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                    </div>
                                </div> */}
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandLimpieza brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MRO Construcción */}
                <div className="container" style={{ marginTop: 40 }}>
                    <div className="row">
                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle=""
                            // title=""
                            description="MRO. Construcción"
                        />
                        <ServiceConstruccion
                            cardStyle="card-style-1"
                            textAlign="text-start"
                        />
                        <div className="container">
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                    </div>
                                </div> */}
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandConstruccion brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* MRO Seguridad */}
                <div className="container" style={{ marginTop: 40 }}>
                    <div className="row">
                        <SectionTitle
                            textAlign="text-center"
                            radiusRounded=""
                            // subtitle=""
                            // title=""
                            description="MRO. Seguridad"
                        />
                        <ServiceSeguridad
                            cardStyle="card-style-1"
                            textAlign="text-start"
                        />
                        <div className="container">
                            {/* <div className="row">
                                    <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign="text-center"
                                            radiusRounded=""
                                            subtitle="Our Awesome Client"
                                            title="Brand Style."
                                            description=""
                                        />
                                    </div>
                                </div> */}
                            <div className="row">
                                <div className="col-lg-12 mt--10">
                                    <BrandSeguridad brandStyle="brand-style-2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <FooterOne />
                <Copyright />
            </main>
        </>
    )
}

export default ServiceMRO
