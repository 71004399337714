import React from 'react';
import { Link } from "react-router-dom";

const Nav = () => {
    return (
        <ul className="mainmenu">

            <li className="has-droupdown">
                <Link to="/">Inicio</Link>
            </li>
            <li>
                <Link to="/maquilado">Maquilado</Link>
            </li>
            <li>
                <Link to="/maquinado">Maquinado</Link>
            </li>
            <li>
                <Link to="/mro">MRO</Link>
            </li>

        </ul>
    )
}
export default Nav;
