import React from 'react';

const BrandList = [
    {
        image: './images/brand/scotch.png'
    },
    {
        image: './images/brand/clorox.png'
    },
    {
        image: './images/brand/glade.png'
    },
    {
        image: './images/brand/dalia.png'
    },
    {
        image: './images/brand/rubbermaid.png'
    },
    {
        image: './images/brand/fapsa.png'
    },
    {
        image: './images/brand/maestro.png'
    },
    {
        image: './images/brand/wiese.png'
    },
    
    
]

const BrandLimpieza = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandLimpieza;
