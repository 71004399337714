import React from 'react';

const BrandList = [
    {
        image: './images/brand/loctite.png'
    },
    {
        image: './images/brand/truper.png'
    },
    {
        image: './images/brand/exito.png'
    },
    {
        image: './images/brand/ansell.png'
    },
    {
        image: './images/brand/tuk.png'
    },
    {
        image: './images/brand/dewalt.png'
    },
    {
        image: './images/brand/austromex.png'
    },
    {
        image: './images/brand/milwaukee.png'
    },
    {
        image: './images/brand/bosch.png'
    },
    {
        image: './images/brand/byp.png'
    },
    {
        image: './images/brand/touch.png'
    },
    
    
]

const BrandFerretero = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href="#"><img src={`${data.image}`} alt="Brand Image" /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFerretero;
