import React from 'react';
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [

    {
        image: '/images/service/maquilado.svg',
        title: 'Maquilado',
        description: 'Socios amigables para tu maquilado, comprometidos con proyectos de ahorro y mejora continua',
        link: '/maquilado'
    },
    {
        image: '/images/service/precision.svg',
        title: 'Maquinado',
        description: 'Creamos el recurso que requiere tu negocio para seguir con la operación. Desde Maquinado hasta fixturas las encuentras con nosotros.',
        link: '/maquinado'
    },
    {
        image: '/images/service/mro.png',
        title: 'MRO Industrial',
        description: 'Encuentra toda la materia prima que requiera tu negocio. desde tornilleria hasta instalaciones eléctricas y aditamentos.',
        link: '/mro'
    },

]
const ServiceThree = ({ textAlign, serviceStyle }) => {
    return (
        <div className="row row--15 service-wraper">
            {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to={`${val.link}`}>
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h4 className="title"><Link to={`${val.link}`} dangerouslySetInnerHTML={{ __html: val.title }}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{ __html: val.description }}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;