import React from 'react';

import { Link } from "react-router-dom"


const callToActionData = {
    title: "¿Te ayudamos a encontrar lo que buscas?",
    subtitle: "Finest choice for your home & office",
    btnText: "Contactar",
}



const CalltoActionSix = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-6">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-12">
                        <div className="inner">
                            <div className="content text-start">
                                <h2 className="title">{callToActionData.title}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="call-to-btn text-start text-lg-right mt_md--20 mt_sm--20">
                            <div className="button-group mt--40 mt_sm--20">
                                <Link to="/contact"><button className="btn-default btn-icon">  {callToActionData.btnText}</button></Link>
                            </div>
                            {/* <a className="btn-default">
                                <Link to="/contact">
                                    {callToActionData.btnText}
                                </Link>
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionSix;